import React, { useEffect, useState } from "react";
import "./Logout.scss";

interface LogoutProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const Logout: React.FC<LogoutProps> = ({ onConfirm, onCancel }) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(window.sessionStorage.getItem("token") || "");
  }, []);

  return (
    <div className="logout-popup">
      {token?.length ? (
        <div className="popup-content">
          <p className="popup-prompt">ARE YOU SURE YOU WANT TO SIGN OUT?</p>
          <button className="popup-button popup-confirm" onClick={onConfirm}>
            Yes
          </button>
          <button className="popup-button popup-deny" onClick={onCancel}>
            No
          </button>
        </div>
      ) : (
        <div className="popup-content">
          <p className="popup-prompt">YOU ARE ALREADY SIGNED OUT</p>
          <button className="popup-button popup-deny" onClick={onCancel}>
            OK
          </button>
        </div>
      )}
    </div>
  );
};

export default Logout;
