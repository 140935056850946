import React from "react";
import "./Link.scss";

const Link = ({ children, uri, text }) => {
  return (
    <div className="link-wrapper">
      <a className="contact-link row-center" href={uri}>
        {children}
        <p>{text}</p>
      </a>
    </div>
  );
};

export default Link;
