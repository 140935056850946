import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import BurgerMenu from "../../BurgerMenu/BurgerMenu";
import { IMAGE_BASE_URI } from "../../Constants";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import { useMobile } from "../../../context/MobileContext";
import "./Register.scss";
import "../Account.scss";
import Throbber from "../../Home/Contact/Icons/Whatsapp/Throbber";

const Register = ({ setPage }) => {
  const [doRedirect, setDoRedirect] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
    kalendarKey: "",
  });
  const [response, setResponse] = useState({
    message: "",
    recieved: false,
    ok: false,
    body: null,
  });

  useEffect(() => {
    if (response.ok && response?.body?.token) {
      // save response token and after a few seconds redirect to home page
      window.sessionStorage.setItem("username", response.body.username);
      window.sessionStorage.setItem("token", response.body.token);
      setDoRedirect(true);
    }

    // data cleanup
  }, [response]);

  useEffect(() => {
    if (doRedirect) {
      login();
      navigate("/adventure");
    }
  }, [doRedirect]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL + "/create-user/";
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors", // Enable cross-origin requests
      // credentials: "include", // Include credentials (cookies, HTTP authentication)
    });
    const body = await response.json();
    setResponse({
      message: body.message,
      recieved: true,
      ok: response.ok,
      body: body,
    });
    setLoading(false);
  };

  return (
    <div className="account-page">
      {isMobile ? <Navbar /> : <BurgerMenu />}
      <section className="full-page centered-container">
        <form
          className="authenticate-form col-start"
          action=""
          id="register-form"
          onSubmit={handleSubmit}
        >
          <div className="main-logo col-start">
            <img
              className="account-page-logo"
              src={`${IMAGE_BASE_URI}Logo-colour.png`}
              alt=""
            />
          </div>
          <label htmlFor="username">Username</label>
          <input type="text" name="username" onChange={handleChange} />
          <label htmlFor="email">Email</label>
          <input type="email" name="email" onChange={handleChange} />
          <label htmlFor="password">Password</label>
          <input type="password" name="password" onChange={handleChange} />
          <label htmlFor="kalendarKey">Kalendar Key</label>
          <input type="text" name="kalendarKey" onChange={handleChange} />
          <div>
            <button className="login-button" type="submit">
              Register
            </button>
            <button className="clear-button" type="reset">
              Clear
            </button>
          </div>
          {response.recieved && (
            <p className={response.ok ? "success-message" : "failure-message"}>
              {response.message}
            </p>
          )}
        </form>
        <div className="register-link">
          BACK TO <a onClick={() => setPage("login")}>SIGN_IN</a>
        </div>
      </section>
      <div className={`save-widget row-start ${!loading && "hidden"}`}>
        <Throbber />
        <p>Creating Account</p>
      </div>
    </div>
  );
};

export default Register;
