import React from "react";
import { BLURB, IMAGE_BASE_URI } from "../../Constants";
import HeaderLink from "./HeaderLink/HeaderLink";
import Bat from "./SVGs/Bat";
import FireElemental from "./SVGs/Fire-Elemental";
import "./Header.scss";
import WitchHat from "./SVGs/WitchHat";

const Header = () => {
  return (
    <section id="header" className="full-page col-start">
      <div className="card" id="card-logo">
        <div className="main-logo col-start">
          <img src={`${IMAGE_BASE_URI}Logo-colour.png`} alt="" />
        </div>
      </div>
      <div className="card" id="card-blurb">
        <p>{BLURB}</p>
      </div>

      <div className="row-center" id="card-links">
        <HeaderLink linkName="Learn More" link="#about">
          <Bat />
        </HeaderLink>
        <HeaderLink linkName="Get in touch" link="#contact">
          <div className="contact-icon">
            <FireElemental />
          </div>
        </HeaderLink>
        <HeaderLink linkName="Adventure" link="/adventure">
          <WitchHat />
        </HeaderLink>
      </div>
    </section>
  );
};

export default Header;
