import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import "./BurgerMenu.scss";
import Logout from "../Account/Logout/Logout";
import { Link } from "react-router-dom";

const BurgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState("");
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const { logout } = useAuth();
  const HOME = "home";
  const ACCOUNT = "account";
  const ADVENTURE = "adventure";

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      setActive(HOME);
    } else if (path === "/account") {
      setActive(ACCOUNT);
    } else if (path === "/adventure") {
      setActive(ADVENTURE);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  const confirmLogout = () => {
    setShowLogoutPopup(false);
    logout();
  };

  const cancelLogout = () => {
    setShowLogoutPopup(false);
  };

  return (
    <div className={`burger ${isOpen ? "open" : ""}`}>
      <div
        className={`burger-icon ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div className={`burger-menu ${isOpen ? "open" : ""}`}>
        <Link to="/" className={active === HOME && "active"}>
          HOME
        </Link>
        <Link to="/adventure" className={active === ADVENTURE && "active"}>
          ADVENTURE
        </Link>
        <Link to="/account" className={active === ACCOUNT && "active"}>
          ACCOUNT
        </Link>
        <a onClick={handleLogoutClick}>SIGN OUT</a>
      </div>
      {showLogoutPopup && (
        <Logout onConfirm={confirmLogout} onCancel={cancelLogout} />
      )}
    </div>
  );
};

export default BurgerMenu;
