export const isValidDate = (day: number): boolean => {
  // Get today's day of the month
  const today = new Date();
  const todayDay = today.getDate();
  const todayMonth = today.getMonth() + 1;

  // Check if the day is valid
  if (
    isNaN(day) ||
    day < 1 ||
    day > 25 ||
    day > todayDay ||
    todayMonth !== 12
  ) {
    return false;
  }

  return true;
};

export const isToday = (day: number): boolean => {
  const today = new Date();
  const todayDay = today.getDate();
  const todayMonth = today.getMonth() + 1;

  return day === todayDay && todayMonth === 12;
};

export const isPast = (day: number): boolean => {
  const today = new Date();
  const todayDay = today.getDate();
  const todayMonth = today.getMonth() + 1;

  return day <= todayDay && todayMonth === 12;
};
