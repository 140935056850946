import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: React.ReactElement;
  isAuthenticated: boolean;
  path?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  isAuthenticated,
  path,
}) => {
  const token = window.sessionStorage.getItem("token");
  return token?.length ? element : <Navigate to={path || "/account"} />;
};

export default ProtectedRoute;
