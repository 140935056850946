import React from "react";
import "./BigThrobber.scss";
import Throbber from "../Home/Contact/Icons/Whatsapp/Throbber";

const BigThrobber: React.FC = () => {
  return (
    <div className="row-center">
      <div className="spinner">
        <Throbber />
      </div>
    </div>
  );
};

export default BigThrobber;
