import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import Register from "./Register";
import BurgerMenu from "../../BurgerMenu/BurgerMenu";
import { IMAGE_BASE_URI } from "../../Constants";
import { useMobile } from "../../../context/MobileContext";
import "./Register.scss";
import "../Account.scss";
import Navbar from "../../Navbar/Navbar";
import Throbber from "../../Home/Contact/Icons/Whatsapp/Throbber";

const Login = () => {
  const [doRedirect, setDoRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState("login");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  useEffect(() => {
    if (doRedirect) {
      login();
      navigate("/adventure");
    }
  }, [doRedirect]);

  const [data, setData] = React.useState({
    username: "",
    email: "",
    password: "",
    kalendarKey: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError(false);
    setLoading(true);
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors", // Enable cross-origin requests
      credentials: "include", // Include credentials (cookies, HTTP authentication)
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.token) {
          window.sessionStorage.setItem("token", jsonData.token);
          window.sessionStorage.setItem("username", data.username);
          setDoRedirect(true);
        } else {
          setError(true);
          console.error("Authentication failed:", jsonData.message);
        }
      })
      .catch((error) => setError(true))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {page === "login" ? (
        <div className="account-page">
          {isMobile ? <Navbar /> : <BurgerMenu />}
          <section className="full-page centered-container">
            <form
              className="authenticate-form col-start"
              action=""
              onSubmit={handleSubmit}
            >
              <div className="main-logo col-start">
                <img
                  className="account-page-logo"
                  src={`${IMAGE_BASE_URI}Logo-colour.png`}
                  alt=""
                />
              </div>
              <label htmlFor="username">Username</label>
              <input type="text" name="username" onChange={handleChange} />
              <label htmlFor="password">Password</label>
              <input type="password" name="password" onChange={handleChange} />
              <div>
                <button className="login-button" type="submit">
                  Login
                </button>
                <button
                  className="clear-button"
                  type="reset"
                  onClick={() => setError(false)}
                >
                  Clear
                </button>
              </div>
              {error && (
                <div>
                  <p className="failure-message">Login attempt failed</p>
                </div>
              )}
            </form>
            <div className="register-link">
              <b>NOT REGISTERED?</b>{" "}
              <a onClick={() => setPage("register")}>SIGN UP</a> USING YOUR
              KALENDAR KEY
            </div>
          </section>
          <div className={`save-widget row-start ${!loading && "hidden"}`}>
            <Throbber />
            <p>Logging you in</p>
          </div>
        </div>
      ) : (
        <Register setPage={setPage} />
      )}
    </>
  );
};

export default Login;
