import React, { createContext, useContext, useEffect, useState } from "react";

// Create a context to store the mobile device information
interface MobileContextType {
  isMobile: boolean;
}

const MobileContext = createContext<MobileContextType | undefined>(undefined);

// Create a provider component
export const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIfMobile = () => {
      return window.innerWidth <= 768;
    };

    // Set the initial state
    setIsMobile(checkIfMobile());

    // Add a resize event listener to update the state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MobileContext.Provider value={{ isMobile }}>
      {children}
    </MobileContext.Provider>
  );
};

// Create a hook to access the mobile device information
export const useMobile = (): MobileContextType => {
  const context = useContext(MobileContext);
  return context;
};
