import React, { useEffect, useState } from "react";
import "./SavedWidget.scss";
import Throbber from "../../../Home/Contact/Icons/Whatsapp/Throbber";
import Sent from "../../../Home/Contact/Icons/Whatsapp/Sent";

interface EndOfDayProps {
  saving: boolean;
  saved: boolean;
}

const SavedWidget: React.FC<EndOfDayProps> = (saving, saved) => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    if (saved) {
      setShown(true);
      setTimeout(() => {
        setShown(false);
      }, 2000);
    }
  }, [saved]);

  useEffect(() => {
    if (saving) {
      setShown(true);
      setTimeout(() => {
        setShown(false);
      }, 2000);
    }
  }, [saving]);
  return (
    <div className={`save-widget ${!shown && "hidden"}`}>
      {saved ? (
        <div className="row-start">
          <Sent />
          <p>Saved</p>
        </div>
      ) : saving ? (
        <div className="row-start">
          <Throbber />
          <p>Saving...</p>
        </div>
      ) : (
        "Error!"
      )}
    </div>
  );
};

export default SavedWidget;
