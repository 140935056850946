import React from "react";
import About from "./About/About";
import BackGroundImg from "./BackGroundImg/BackGroundImg";
import Contact from "./Contact/Contact";
import Header from "./Header/Header";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import Navbar from "../Navbar/Navbar";
import { useMobile } from "../../context/MobileContext";
import "./Home.scss";

const Home = () => {
  const { isMobile } = useMobile();

  return (
    <>
      {isMobile ? <Navbar /> : <BurgerMenu />}
      <BackGroundImg />
      <Header />
      <About />
      <Contact />
    </>
  );
};

export default Home;
