import React, { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "../Home/Home";
import Adventure from "../Adventure/Adventure";
import AdvDay from "../Adventure/AdvDay/AdvDay";
import Redirect from "../Redirect/Redirect";
import ProtectedRoute from "./ProtectedRoute";

import "../../styles/App.scss"; // don't remove this the whole page breaks
import Login from "../Account/Register/Login";
import { useAuth } from "../../context/AuthContext";
import ValidateAdventureRoute from "./ValidateAdventureRoute";

const AppRouter = () => {
  const { isAuth } = useAuth();
  if (isAuth === null) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/account",
      element: <Login />,
    },
    {
      path: "/adventure",
      element: <Adventure />,
    },
    {
      path: "/adventure/:day",
      element: (
        <ProtectedRoute
          element={<AdvDay />}
          isAuthenticated={isAuth}
          path="/account"
        />
      ),
    },
    {
      path: "*",
      element: <Redirect to={"/"} />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
