export const isAuthenticated = async () => {
  try {
    let token = window.sessionStorage.getItem("token");
    if (token) {
      const response = await fetch("http://localhost:2512/is-authenticated", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        return false;
      }

      const data = await response.json();
      return data.authenticated;
    }
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
};

export const logOut = () => {
  window.sessionStorage.removeItem("token");
  window.sessionStorage.removeItem("username");
  window.location.href = "/";
};
