import React from "react";
import "./Adventure.scss";
import { IMAGE_BASE_URI } from "../Constants";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { useMobile } from "../../context/MobileContext";
import { isPast, isToday, isValidDate } from "../../utils/date";

const Adventure = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  const handleClick = (day: number) => {
    if (isValidDate(day)) {
      navigate(`/adventure/${day}`);
    }
  };

  const RenderCards = () => {
    const cards = [];
    for (let i = 1; i <= 25; i++) {
      cards.push(
        <button
          key={i}
          className={`block ${isToday(i) ? "today" : isPast(i) ? "done" : ""}`}
          onClick={() => handleClick(i)}
        >
          <img
            className="calendar-day"
            src={`${IMAGE_BASE_URI}calendar-days/day_${i}.png`}
          />
        </button>
      );
    }
    return <div className="kalendar-container">{cards}</div>;
  };

  return (
    <div className="adventure-page">
      {isMobile ? <Navbar /> : <BurgerMenu />}
      <section className="full-page col-start" id="adventure">
        <div className="adventure-header">
          <h1>DAILY ADVENTURE</h1>
          <p className="adventure-blurb">
            Welcome, Adventurer, to the Kritmiss Adventure! It’s not
            particularly Xmassy - I realized this whilst I was writing it, but I
            also realized that whilst there may be spells instead of sleigh
            bells, and swords instead of candy canes, an adventure is an
            adventure, and if Die Hard is a Christmas movie, then this shall
            certainly be a Christmas Adventure… for reasons. We don’t do
            spoilers at this table. I also discovered that due to technical
            constraints, and the lack of being face-to-face at a table, it might
            be a little bit ‘railroady’. For this I can only apologize, but
            there was no solution to give all you lovely players a proper way to
            RP as you might normally do. Hopefully chance will put us together
            at a table one day. For now, please enjoy stepping in to the shoes
            of Emdariel, a half-elven ranger who has, perhaps, rolled a few too
            many ones in life…
          </p>
        </div>
        <RenderCards />
      </section>
    </div>
  );
};

export default Adventure;
