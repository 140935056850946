// genrate react Redirect component
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Redirect = ({ to: path }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (path && location.pathname !== path) {
      navigate(path);
    }
  }, [location, navigate]);

  return <></>;
};

export default Redirect;
