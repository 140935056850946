import React, { useState } from "react";
import D20 from "../../../../Icons/D20";
import "./CharacterSheet.scss";

interface CharacterSheetProps {
  currentHp: number;
}

const CharacterSheet: React.FC<CharacterSheetProps> = ({ currentHp }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <button className={`show-character-sheet`} onClick={() => setShow(!show)}>
        <D20 />
      </button>
      <div className={`character-sheet ${show ? "show" : "hidden"}`}>
        <div className="row-start header">
          <div className="stat name">
            <h3>Emdariel</h3>
            <p>Level 4 Ranger</p>
          </div>
          <div className="char-stats row-start">
            <div className="stat hp hp-ac">
              <h3>HP</h3>
              <p>{currentHp}/24</p>
            </div>
            <div className="stat hp-ac">
              <h3>AC</h3>
              <p>15</p>
            </div>
            <div className="stat speed">
              <h3>Speed</h3>
              <p>30ft</p>
            </div>
          </div>
        </div>
        <div className="char-abilities row-start">
          <div className="row-between">
            <div className="stat">
              <h3>STR</h3>
              <p>14</p>
            </div>
            <div className="stat">
              <h3>DEX</h3>
              <p>16</p>
            </div>
            <div className="stat">
              <h3>CON</h3>
              <p>12</p>
            </div>
          </div>
          <div className="row-between">
            <div className="stat">
              <h3>INT</h3>
              <p>10</p>
            </div>
            <div className="stat">
              <h3>WIS</h3>
              <p>14</p>
            </div>
            <div className="stat">
              <h3>CHA</h3>
              <p>8</p>
            </div>
          </div>
        </div>
        <div className="char-stat row-start">
          <div className="stat large">
            <h3>Survival</h3>
            <p>+4</p>
          </div>
          <div className="stat large">
            <h3>Nature</h3>
            <p>+2</p>
          </div>
        </div>
        <div className="char-stat row-start">
          <div className="stat large">
            <h3>Stealth</h3>
            <p>+5</p>
          </div>
          <div className="stat large">
            <h3>Animal Handling</h3>
            <p>+4</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CharacterSheet;
