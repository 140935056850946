import React, { useState } from "react";
import DiceCheck from "../DiceCheck/DiceCheck";
import PlayerTurnInput from "./PlayerTurnInput";
import "./Combat.scss";
import { useParams } from "react-router-dom";

const Combat = ({
  combat,
  hp,
  setHP,
  save,
  savePara,
  index,
  incrementLast,
}) => {
  const { day } = useParams();
  const [round, setRound] = useState(0);
  const [started, setStarted] = useState(false);
  const [ended, setEnded] = useState(false);
  const [order, setOrder] = useState([]);
  const [completedStack, setCompletedStack] = useState(
    save?.combat?.turns || []
  );
  const [nextTurn, setNextTurn] = useState(0);
  const playerOptions = combat.player.options;
  const [weapon, setWeapon] = useState(0);
  const [weaponSelected, setWeaponSelected] = useState(false);
  const [turnDamage, setTurnDamage] = useState(0);
  const [combatants, setCombatants] = useState({
    player: {
      type: "player",
      initiative: undefined,
      completedTurns: [],
      ...combat.player,
    },
    treeBreaker: {
      type: "treeBreaker",
      initiative: 0,
      ...combat.treeBreaker,
    },
    enemy: {
      type: "enemy",
      initiative: 0,
      ...combat.enemy,
    },
  });

  const startInitiative = () => {
    console.log(nextTurn);
    const playerInit = combatants.player.initiative;
    const treeBreakerInit =
      parseInt(day) !== 10 ? Math.floor(Math.random() * 20) + 1 : 0;

    const enemyInit =
      parseInt(day) !== 10 ? Math.floor(Math.random() * 20) + 1 : 0.1;
    const { player, treeBreaker, enemy } = combatants;
    console.log(combatants);
    if (combatants.player.options.length === 1) setWeaponSelected(true);

    player.initiative = playerInit;
    treeBreaker.initiative = treeBreakerInit;
    enemy.initiative = enemyInit;
    let orderByName: string[] = [player, treeBreaker, enemy]
      .sort((a, b) => b.initiative - a.initiative)
      .map((combatant) => {
        return combatant.type;
      });
    console.log(orderByName);
    let finalOrder: string[] = [];
    for (let i = 0; i < combat.rounds; i++) {
      const orderWithRound = [`Round ${i + 1}`, ...orderByName];
      finalOrder = [...finalOrder, ...orderWithRound];
    }
    console.log(finalOrder);
    setOrder(finalOrder);
    setStarted(true);
    setCompletedStack([finalOrder[0]]);
  };

  const handlePlayerDamage = (text, damage) => {
    const newEnemyHp = combatants.enemy.hp - damage;
    setCombatants({
      ...combatants,
      enemy: {
        ...combatants.enemy,
        hp: newEnemyHp,
      },
      player: {
        ...combatants.player,
        completedTurns: [...combatants.player.completedTurns, text],
      },
    });
    setWeaponSelected(false);
    incrementTurn();
  };

  const mapTurns = (turn, i) => {
    const turnRound = Math.floor(i / 4);
    if (turn === "player") {
      if (combatants.player.completedTurns.length > turnRound) {
        return (
          <div className="combat-turn" key={i}>
            <h3>Your Turn</h3>
            <p>{combatants.player.completedTurns[turnRound]}</p>
          </div>
        );
      }
      return (
        <div className="combat-turn" key={i}>
          <h2>Your Turn</h2>
          {!weaponSelected && (
            <div className="combat-choice-wrapper">
              {playerOptions.map((option, i) => {
                return (
                  <button
                    key={i}
                    onClick={() => {
                      setWeapon(i);
                      setWeaponSelected(true);
                    }}
                  >
                    <p>{option.name}</p>
                    <p className="help-text">{`+${option.toHit} to hit, 1D${option.damageDie} + ${option.damageBonus} damage`}</p>
                  </button>
                );
              })}
            </div>
          )}

          {weaponSelected && (
            <PlayerTurnInput
              round={turnRound}
              option={playerOptions[weapon]}
              enemyAC={combatants.enemy.ac}
              handleResolution={handlePlayerDamage}
            />
          )}
        </div>
      );
    }
    const isPresentTurn = i + 1 === completedStack.length;
    if (turn === "treeBreaker") {
      return (
        <div className="combat-turn" key={i}>
          <h3>Tree Breaker's Turn</h3>
          <p>{combatants.treeBreaker.turns[turnRound].text}</p>
          {isPresentTurn && !ended && (
            <button
              className="next-turn"
              onClick={() => {
                handleNonPlayers();
                incrementTurn();
              }}
            >
              Next Turn
            </button>
          )}
        </div>
      );
    }
    if (turn === "enemy") {
      return (
        <div className="combat-turn" key={i}>
          <h3>{combatants.enemy.name}</h3>
          <p>{combatants.enemy.turn[turnRound].text}</p>
          {turnDamage > 0 && <p>{`You take ${turnDamage} Damage`}</p>}
          {isPresentTurn && !ended && (
            <button
              className="next-turn"
              onClick={() => {
                handleNonPlayers();
                incrementTurn();
              }}
            >
              Next Turn
            </button>
          )}
        </div>
      );
    }
    return (
      <div key={i}>
        <h2 className="round-header">{turn}</h2>
        {isPresentTurn && !ended && (
          <button
            className="next-turn"
            onClick={() => {
              handleNonPlayers();
              incrementTurn();
            }}
          >
            Begin Round
          </button>
        )}
      </div>
    );
  };

  const mapCompletedTurns = (turn, i) => {
    const turnRound = Math.floor(i / 4);
    if (turn === "player") {
      return (
        <div className="combat-turn" key={i}>
          <h3>Your Turn</h3>
          <p>{save.combat.playerTurns[turnRound]}</p>
        </div>
      );
    }
    if (turn === "treeBreaker") {
      return (
        <div className="combat-turn" key={i}>
          <h3>Tree Breaker's Turn</h3>
          <p>{combatants.treeBreaker.turns[turnRound].text}</p>
        </div>
      );
    }
    if (turn === "enemy") {
      return (
        <div className="combat-turn" key={i}>
          <h3>{combatants.enemy.name}</h3>
          <p>{combatants.enemy.turn[turnRound].text}</p>
        </div>
      );
    }
    return (
      <div key={i}>
        <h2 className="round-header">{turn}</h2>
      </div>
    );
  };

  const incrementTurn = () => {
    if (nextTurn + 1 === order.length) {
      setEnded(true);
      incrementLast();
      savePara(index, {
        combat: {
          turns: completedStack,
          outCome: "victory",
          damageTaken: 0,
          damageDealt: combat.enemy.hp - combatants.enemy.hp,
          playerTurns: combatants.player.completedTurns,
        },
        completed: true,
        type: "combat",
      });
    } else {
      if (order[nextTurn + 1].includes("Round")) {
        setRound(round + 1);
      }
      setCompletedStack([...completedStack, order[nextTurn + 1]]);
      setNextTurn(nextTurn + 1);
    }
  };

  const handleNonPlayers = () => {
    console.log(nextTurn);
    if (order[nextTurn + 1] === order.length) return;
    if (order[nextTurn + 1] === "treeBreaker") {
      const newEnemyHp =
        combatants.enemy.hp - combatants.treeBreaker.turns[round].damage;
      console.log(newEnemyHp);
      setCombatants({
        ...combatants,
        enemy: {
          ...combatants.enemy,
          hp: newEnemyHp,
        },
      });
    }
    if (order[nextTurn + 1] === "enemy") {
      const newPlayerHp = hp - combatants.enemy.turn[round].damage;
      setHP(newPlayerHp);
    }
  };

  return (
    <>
      {!save?.completed ? (
        <div>
          <div className="dice-check">
            <p className="check-prompt">
              {"Roll "}
              <span>Inititative</span>
            </p>
            <div className="input-container">
              <input
                type="number"
                onChange={(e) =>
                  setCombatants({
                    ...combatants,
                    player: {
                      ...combatants.player,
                      initiative: e.target.value,
                    },
                  })
                }
                placeholder="Enter your roll"
                disabled={started}
              />
              <button onClick={startInitiative} disabled={started}>
                Submit
              </button>
            </div>
          </div>
          {started && (
            <>
              <div>{completedStack.map((turn, i) => mapTurns(turn, i))}</div>
            </>
          )}{" "}
          {
            ended && <p className="adventure-text">{combat.epilogue}</p>
            // Roll initiative
            // Display in order of initiative
            // Show epilogue once the rounds are completed
          }
        </div>
      ) : (
        <div>
          {save.combat.turns.map((turn, i) => mapCompletedTurns(turn, i))}
        </div>
      )}
    </>
  );
};

export default Combat;
