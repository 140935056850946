import React, { useRef, useState } from "react";
import emailJs from "@emailjs/browser";
import ProgDisplay from "./ProgDisplay/ProgDisplay";
import Throbber from "../Icons/Whatsapp/Throbber";
import Sent from "../Icons/Whatsapp/Sent";
import Failed from "../Icons/Whatsapp/Failed";
import "./Form.scss";

const Form = () => {
  const IN_PROGRESS: String = "PROGRESS";
  const DONE: String = "DONE";
  const NOT_STARTED: String = "NOTSTARTED";
  const ERROR: String = "ERROR";

  // **** TODO move these
  const service_ID = "service_l6lsqt9";
  const template_ID = "template_cpjipte";
  const options = "WS-0PB5C5qMw7a6kb";

  const [progress, setProgress] = useState(NOT_STARTED);
  const form = useRef();

  const sendEmail = (event: React.FormEvent) => {
    event.preventDefault();
    setProgress(IN_PROGRESS);

    emailJs
      .sendForm(service_ID, template_ID, form.current, options)
      .then(() => setProgress(DONE))
      .catch((error) => {
        setProgress(ERROR);
        console.log(error);
      });
  };

  return (
    <div className="card" id="email">
      <form ref={form} onSubmit={sendEmail}>
        <h2>Get in touch to order yours now</h2>
        <textarea name="body"></textarea>
        <div className="row-between inputs">
          <div className="input-wrapper">
            <label htmlFor="sender-name">Name</label>
            <input name="sender-name" />
          </div>
          <div className="input-wrapper">
            <label htmlFor="sender-email">Email</label>
            <input name="sender-email" type="email" />
          </div>
        </div>
        <div className="row-between bottom-bar">
          <div className="progress">
            <ProgDisplay
              text="Sending"
              className="loading"
              hidden={!(progress == IN_PROGRESS)}
            >
              <Throbber />
            </ProgDisplay>
            <ProgDisplay
              text="Sent"
              className="sent"
              hidden={!(progress == DONE)}
            >
              <Sent />
            </ProgDisplay>
            <ProgDisplay
              text="There was an error. Please try again later"
              className="error"
              hidden={!(progress == ERROR)}
            >
              <Failed />
            </ProgDisplay>
          </div>
          <div className="buttons row-between">
            <button id="send-btn" name="send" type="submit">
              Send Email
            </button>
            <button id="clear-btn" name="clear" type="reset">
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
