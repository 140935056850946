import React from "react";
import "./HeaderLink.scss";
import { Link } from "react-router-dom";

const HeaderLink = ({ link, linkName, children }) => {
  return (
    <div className="col-start header-link card">
      {link?.length && link.startsWith("#") ? (
        <a href={link}>
          {children}
          <h2>{linkName}</h2>
        </a>
      ) : (
        <Link to={link}>
          {children}
          <h2>{linkName}</h2>
        </Link>
      )}
    </div>
  );
};

export default HeaderLink;
