import React, { useEffect, useState } from "react";
import "./AdvDay.scss";
import { useNavigate, useParams } from "react-router-dom";
import Choices from "./Choice/Choices";
import EndOfDay from "./EndOfDay/EndOfDay";
import Navbar from "../../Navbar/Navbar";
import BurgerMenu from "../../BurgerMenu/BurgerMenu";
import { useMobile } from "../../../context/MobileContext";
import { IMAGE_BASE_URI } from "../../Constants";
import DiceCheck from "./DiceCheck/DiceCheck";
import SavedWidget from "./SavedWidget/SavedWidget";
import BigThrobber from "../../BigThrobber/BigThrobber";
import { isValidDate } from "../../../utils/date";
import D20 from "../../../Icons/D20";
import CharacterSheet from "./CharacterSheet/CharacterSheet";
import Combat from "./Combat/Combat";

const AdvDay: React.FC = () => {
  const navigate = useNavigate();
  const { day } = useParams();

  useEffect(() => {
    const dayNum = parseInt(day);
    if (!isValidDate(dayNum)) {
      window.location.pathname = "/adventure";
    }
  }, [day]);

  const [adventure, setAdventure] = useState(null);
  const { isMobile } = useMobile();

  const [savedDay, setSavedDay] = useState(null);
  const [loading, setLoading] = useState(true);
  const [today, setToday] = useState(false);
  const [last, setLast] = useState(0);
  const [shownParas, setShownParas] = useState([]);
  const [end, setEnd] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [hp, setHP] = useState(24);

  const incrementLast = () => {
    let next = last + 1;
    if (adventure?.paragraphs?.length && next > adventure.paragraphs.length) {
      setEnd(true);
      return;
    } else {
      while (adventure.paragraphs[next - 1].type === "text") {
        if (
          // adventure?.paragraphs?.length &&
          next >
          adventure?.paragraphs?.length - 1
        ) {
          setEnd(true);
          break;
        }
        next++;
      }
      console.log(next);
      setLast(next);
    }
  };

  const saveDay = async (body) => {
    setSaving(true);
    const dayIndex = parseInt(day) - 1;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/save-day/${dayIndex}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ data: body }),
      }
    );
    if (response.ok) {
      console.log("Day saved successfully");
    } else if (response.status === 403) {
      console.error("Unauthorized");
      navigate("/account");
    }
    setSaved(true);
    setSaving(false);
  };

  const saveParagraph = async (index, body) => {
    setSaving(true);
    const dayIndex = parseInt(day) - 1;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/save-paragraph/${dayIndex}/${index}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ data: body }),
      }
    );
    if (response.ok) {
      console.log("Paragraph saved successfully");
    } else if (response.status === 403) {
      console.error("Unauthorized");
      navigate("/account");
    } else {
      console.error("Error saving paragraph");
    }
    setSaved(true);
    setSaving(false);
  };

  useEffect(() => {
    if (loading) return;
    const shownParas = adventure?.paragraphs?.slice(0, last) || [];
    setShownParas(shownParas);
  }, [last, loading]);

  const mapParagraphs = (paragraph, index) => {
    switch (paragraph.type) {
      case "text":
        return (
          <p className="adventure-text" key={index}>
            {paragraph.text}
          </p>
        );
      case "diceCheck":
        return (
          <DiceCheck
            key={index}
            index={index}
            diceCheck={paragraph.diceCheck}
            savedRoll={savedDay.paras[index]}
            result={
              savedDay.paras[index].completed
                ? savedDay.paras[index].diceSuccess
                : null
            }
            setLast={incrementLast}
            savePara={saveParagraph}
          />
        );
      case "choices":
        return (
          <Choices
            key={index}
            index={index}
            choices={paragraph.choices}
            savedChoices={savedDay.paras[index]}
            incrementLast={incrementLast}
            savePara={saveParagraph}
          />
        );
      case "combat":
        return (
          <Combat
            key={index}
            index={index}
            savePara={saveParagraph}
            combat={paragraph.combat}
            save={savedDay.paras[index]}
            hp={hp}
            setHP={setHP}
            incrementLast={incrementLast}
          />
        );
      default:
        return <p key={index}>Unknown paragraph type</p>;
    }
  };

  const setUpStory = async (paras) => {
    const storyParas = [];
    let firstInterractionOccurred = false;
    let allowSetLast = true;
    for (let index = 0; index < paras.length; index++) {
      const para = paras[index];
      if (para.type === "text") {
        storyParas.push({
          type: "text",
          completed: true,
        });
      } else if (para.type === "diceCheck") {
        storyParas.push({
          type: "diceCheck",
          completed: false,
        });
        firstInterractionOccurred = true;
      } else if (para.type === "choices") {
        storyParas.push({
          type: "choices",
          completed: false,
        });
        firstInterractionOccurred = true;
      } else if (para.type === "combat") {
        storyParas.push({
          type: "combat",
          completed: false,
          turns: [],
        });
        firstInterractionOccurred = true;
      }
      if (allowSetLast && firstInterractionOccurred) {
        setLast(index + 1);
        allowSetLast = false;
      }
    }
    setSavedDay((prev) => ({
      ...prev,
      started: true,
      paras: storyParas,
    }));
    await saveDay({
      started: true,
      paras: storyParas,
    });
  };

  useEffect(() => {
    //fecth adventure Data and user save data
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/adventure/${day}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
            },
          }
        ).catch((error) => {
          console.error("Error fetching data:", error);
          if (error.status === 403) navigate("/account");
          else navigate("/");
          return null;
        });
        // Fetch baseline Adventure data
        const result = await response.json();
        setToday(result.today);
        setAdventure(result.adventure);
        // Fetch user save data
        const dayIndex = parseInt(day) - 1;
        const saveResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/saved-day/${dayIndex}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
            },
          }
        );
        const saveResult = await saveResponse.json();
        if (saveResult?.day) {
          console.log(saveResult.day);
          setSavedDay(saveResult.day);
          if (
            saveResult.day.paras.length === 0 ||
            saveResult.day.paras.includes(null)
          )
            await setUpStory(result.adventure.paragraphs);
          else {
            const paras = saveResult.day.paras;
            const uncompleted = paras.findIndex((p) => !p.completed);
            if (uncompleted === -1) {
              setLast(paras.length);
              setEnd(true);
            } else {
              setLast(paras.findIndex((p) => !p.completed) + 1);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/account");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [day]);

  return !loading && adventure && savedDay && shownParas ? (
    <>
      {isMobile ? <Navbar /> : <BurgerMenu />}
      <CharacterSheet currentHp={hp} />
      <section
        className="full-page col-start daily-adventure"
        id="daily-adventure"
      >
        <SavedWidget saving={true} saved={true} />
        <div className="page col-start card">
          <div className="row-start adventure-header">
            <img src={`${IMAGE_BASE_URI}calendar-days/day_${day}.png`} alt="" />
            <div>
              <h2>Day {day}</h2>
              <h1>{adventure?.title}</h1>
            </div>
          </div>
          {shownParas.map(mapParagraphs)}
          {end && <EndOfDay today={today} day={parseInt(day)} />}
        </div>
      </section>
    </>
  ) : (
    <BigThrobber />
  );
};

export default AdvDay;
