import React, { useState } from "react";
import "./Choices.scss";

const Choices = ({ choices, incrementLast, savedChoices, savePara, index }) => {
  const [outcome, setOutcome] = useState(
    savedChoices.completed ? savedChoices.choices.index : 0
  );
  const [choiceMade, setChoiceMade] = useState(savedChoices.completed);
  const choose = (e) => {
    setOutcome(e.target.value);
    setChoiceMade(true);
    incrementLast();
    const body = {
      choices: {
        index: e.target.value,
      },
      completed: true,
      type: "choices",
    };
    savePara(index, body);
  };

  return (
    <div className="choices-box" key={index}>
      <div className="row-start choice-container">
        {" "}
        {choices.map((choice, i) => (
          <button
            className={choiceMade && outcome != i ? "unselected" : ""}
            key={i}
            onClick={choose}
            disabled={choiceMade}
            value={i}
          >
            {choice.option}
          </button>
        ))}
      </div>
      <p className="adventure-text">{choiceMade && choices[outcome].outcome}</p>
    </div>
  );
};

export default Choices;
