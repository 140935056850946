import React, { useState } from "react";
import "./Combat.scss";

const PlayerTurnInput = ({ option, handleResolution, enemyAC, round }) => {
  const [attackValue, setAttackValue] = useState(undefined);
  const [attackSuccess, setAttackSuccess] = useState(false);
  const [damageValue, setDamageValue] = useState(undefined);

  const handleDamage = () => {
    handleResolution(option.success[round], damageValue);
  };

  const handleAttack = () => {
    if (attackValue >= enemyAC) {
      setAttackSuccess(true);
    } else {
      handleResolution(option.fail[round], 0);
    }
  };

  return (
    <div>
      <p>Use your {option.name}</p>
      <p className="help-text">{`(+${option.toHit} to hit, 1D${option.damageDie} + ${option.damageBonus} damage)`}</p>
      <div className="attack-die-box">
        <input
          type="number"
          disabled={attackSuccess}
          value={attackValue}
          placeholder="Enter your roll"
          onChange={(e) => {
            setAttackValue(e.target.value);
          }}
        />

        <button onClick={() => handleAttack()}>Attack!</button>
      </div>
      {attackSuccess && (
        <div className="attack-die-box">
          <input
            type="number"
            value={damageValue}
            onChange={(e) => setDamageValue(e.target.value)}
          />
          <button onClick={() => handleDamage()}>Deal Damage</button>
        </div>
      )}
    </div>
  );
};

export default PlayerTurnInput;
