import React from "react";
import "./EndOfDay.scss";

interface EndOfDayProps {
  day: number;
  today: boolean;
}

const EndOfDay: React.FC<EndOfDayProps> = ({ day, today }) => {
  const christmas: boolean = day >= 25;
  const nextDay: string = (day + 1).toString();
  return (
    <div className="end-of-day">
      <h2>This is the end of today's adventure!</h2>
      {/* {today ? ( */}
      <p>
        Thank you for playing today's adventure. Come back tomorrow for the next
        chapter
      </p>
      {/* ) : christmas ? (
        <p>Thank you for playing. Merry Christmas!</p>
      ) : ( */}
      {/* // <a href={"/adventure/" + nextDay}>Go to next day</a> */}
      {/* )} */}
    </div>
  );
};

export default EndOfDay;
