import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logout from "../Account/Logout/Logout";
import "./Navbar.scss";
import { useAuth } from "../../context/AuthContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState("");
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const { logout } = useAuth();

  const HOME = "home";
  const ACCOUNT = "account";
  const ADVENTURE = "adventure";
  const ADVENTURE_DAY = "adventure-day";
  const adventureDayRegex = /^\/adventure\/(\d+)$/;

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      setActive(HOME);
    } else if (path === "/account") {
      setActive(ACCOUNT);
    } else if (path === "/adventure") {
      setActive(ADVENTURE);
    } else if (adventureDayRegex.test(path)) {
      setActive(ADVENTURE_DAY);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  const confirmLogout = () => {
    setShowLogoutPopup(false);
    logout();
  };

  const cancelLogout = () => {
    setShowLogoutPopup(false);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="navbar">
      <div className="navbar-header">
        {active === ADVENTURE_DAY && (
          <div className="back-button" onClick={goBack}>
            &larr;
          </div>
        )}
        <div
          className={`toggle ${isOpen ? "open" : ""} ${
            active === ADVENTURE_DAY && "move-left"
          }`}
          onClick={toggleMenu}
        >
          MENU
        </div>
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <Link to="/" className={`nav-link ${active === HOME && "active"}`}>
          HOME
        </Link>
        <Link
          to="/adventure"
          className={`nav-link ${active === ADVENTURE && "active"}`}
        >
          ADVENTURE
        </Link>
        <Link
          to="/account"
          className={`nav-link ${active === ACCOUNT && "active"}`}
        >
          ACCOUNT
        </Link>
        <a onClick={handleLogoutClick} className="nav-link">
          SIGN OUT
        </a>
      </div>
      {showLogoutPopup && (
        <Logout onConfirm={confirmLogout} onCancel={cancelLogout} />
      )}
    </div>
  );
};

export default Navbar;
